:root {
    --font-family: sans-serif;
    --font-size-small: 0.7em;
    --font-size-normal: 1em;
    --font-size-large: 1.3em;
    --font-size-big: 2em;
    --font-size-extra-big: 3em;
    --font-size-mobile-small: 1.5em;
    --font-size-mobile-normal: 3em;
    --font-size-mobile-large: 4em;
    --font-size-mobile-big: 5em;
    --space-small: 0.5em;
    --space-normal: 1em;
    --space-normal-row: 1.5em;
    --space-normal-row-2: 1.2em;
    --space-big: 2em;
    --control-height: 2.3em;
    --control-label-pos: 0.2em;
    --color-1: rgb(70, 70, 70);
    --color-2: darkgray;
    --color-3: #e0e0e0;
    --color-3-light: #e0e0e075;
    --color-4: #fff;
    --color-success: green;
    --color-error: #ff0000;
    --color-error-light: #ff000073;
    --color-info: blue;
    --shadow-color-dark: rgba(0, 0, 0, 0.75);
    --shadow-color-normal: rgba(0, 0, 0, 0.5);
    --shadow-color-light: rgba(0, 0, 0, 0.3);
    --shadow-color-ultralight: rgba(0, 0, 0, 0.17);
    --corporate-color-1: #003459;
    --corporate-color-1-light: #00345966;
    --corporate-color-2: #005980;
    --corporate-color-3: #007ea7;
    --corporate-color-4: #64a6c1;
    --corporate-color-5: #96bace;
    --corporate-color-5l: #96bace5e;
    --corporate-color-6: #c7cedb;
    --corporate-color-6-light: #c7cedbbf;
    --corporate-color-6-ultralight: #c7cedb40;
    --corporate-color-7: #ffd166;
    --corporate-color-7-light: #ffd16626;
    --corporate-color-8: #e3d0a1;
    --corporate-color-9: #a5668b;
    --corporate-color-10: #70163c;
    --corporate-color-10-light: #70163c80;
    --corporate-color-11: #0ea700e1;
    /* Top badge colors a, s, d */
    --corporate-color-12: #ff3a20;
    --corporate-color-14: #0caadc;
    --corporate-color-13: #16db65;
  }
  body {
    font-family: "Roboto", sans-serif;
  }
  /* Header */
  
  .screen-container .topbar-container .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background-color: var(--corporate-color-1);
    color: var(--corporate-color-7);
    padding-left: 24px;
    padding-right: 24px;
  }
  
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    background-color: var(--corporate-color-1);
    color: var(--corporate-color-7);
    padding-left: 24px;
    padding-right: 24px;
    flex-wrap: wrap;
  }
  
  /* p */
  
  .claim {
    color: var(--corporate-color-1);
    font-size: var(--font-size-large);
  }
  
  .side-shuffled-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 90%;
  }
  
  .footnotes {
    font-size: var(--font-size-normal);
    color: var(--corporate-color-4);
  }
  
  .solution {
    font-size: var(--font-size-extra-big);
    color: white;
    margin-top: 24px;
  }
  
  .top-margin {
    margin-top: 36px;
  }
  
  .contact-form-label {
    margin-bottom: 6;
    margin-top: 12;
  }
  
  .header-footer-button-text {
    color: white;
    margin-left: 12;
  }
  
  /* Button */
  
  .page-button:hover {
    color: var(--corporate-color-2);
    background-color: var(--corporate-color-7);
  }
  
  /* Checkbox */
  
  .contact-checkbox {
    width: 80%;
    margin-top: 24;
  }
  
  /* Content Box */
  
  .content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 100px;
    margin: 20px;
    border-radius: 50px;
  }
  
  .content-box-text {
    width: 90%;
    font-size: var(--font-size-large);
    color: var(--corporate-color-1);
    text-align: justify;
    text-justify: auto;
  }
  
  .margin-left {
    width: 80%;
  }
  
  .top-margin {
    margin-top: 36px;
  }
  
  .box-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .row-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 340px !important;
    box-shadow: 0px 0px 5px 0px black;
    border-radius: 20px;
    padding: 20px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .row-column-solution {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    min-width: var(--row-element-min-width-1);
    margin-top: 72px;
    margin-left: 15px;
    padding: 12px;
    background-color: var(--corporate-color-6);
    border-radius: 12px;
    text-align: center;
  }
  
  /* Fields */
  
  .contact-dropdown {
    width: 375;
  }
  
  .contact-textfield {
    width: 80vw;
    height: 80;
  }
  
  /* ICONS */
  
  .header-footer-solution-icon {
    font-size: 25px;
  }
  
  .header-footer-login-icon {
    font-size: 30px;
  }
  
  /* Mobile Setting */
  
  @media screen and (min-width: 1170px), (orientation: portrait) {
    :root {
      --row-element-min-width-1: 800px;
      --row-element-min-width-2: 800px;
      --contact-box-height: 1400;
      --height: 100px;
    }
  
    .p-lp {
      font-size: var(--font-size-mobile-small);
      text-align: center;
      color: var(--corporate-color-1);
      text-align: justify;
      text-justify: auto;
    }
  
    .claim {
      color: var(--corporate-color-1);
      font-size: var(--font-size-big);
    }
  
    .content-box-text {
      font-size: var(--font-size-mobile-small);
      text-align: center;
      color: var(--corporate-color-1);
      text-align: justify;
      text-justify: auto;
    }
  
    .margin-left {
      width: 80%;
    }
  
    .top-bottom-margin {
      margin-top: 12;
      margin-bottom: 12;
    }
  
    .top-margin {
      margin-top: 36px;
    }
  
    .header-footer-button-text {
      color: white;
      margin-left: 18;
      font-size: var(--font-size-normal);
    }
  
    .content-box-headline {
      font-size: var(--font-size-mobile-big);
      color: var(--corporate-color-4);
      text-align: center;
      margin-left: 24px;
      margin-right: 24px;
    }
  
    .content-box-column-headline {
      font-size: var(--font-size-mobile-large);
      color: var(--corporate-color-4);
      text-align: center;
    }
  
    .content-box-subheadline {
      font-size: var(--font-size-mobile-large);
      color: var(--corporate-color-1);
      text-align: center;
      margin-top: 72px;
      margin-bottom: 24px;
    }
  
    .emphasized-text {
      background-color: var(--corporate-color-6);
      font-size: var(--font-size-mobile-large);
      color: white;
    }
  
    .page-button {
      width: 50%;
      height: 70px;
      background-color: var(--corporate-color-2);
      color: white;
      margin-top: 72px;
      font-size: var(--font-size-mobile-normal);
    }
  
    .contact-dropdown {
      width: 80vw;
      height: 80;
    }
  
    .contact-textfield {
      width: 70vw;
      height: 80;
    }
  
    .contact-large-textfield {
      width: 70vw;
      height: 400;
    }
  
    .screen-container .topbar-container .header {
      height: 100;
    }
  
    .screen-container .topbar-container .header button {
      font-size: var(--font-size-mobile-normal);
    }
  
    .footer {
      height: 200;
      justify-content: center;
    }
  
    .footer button {
      font-size: var(--font-size-mobile-normal);
    }
  
    .row-column-solution {
      width: 80vw;
    }
  
    .row-column-solution input {
      font-size: var(--font-size-mobile-normal);
    }
  
    .row-column-solution textarea {
      font-size: var(--font-size-mobile-normal);
    }
  
    .row-column-solution .label {
      font-size: var(--font-size-big);
    }
  
    .row-column-solution .checkbox input {
      height: 2.5em;
      width: 2.5em;
      margin-right: 1em;
    }
  
    .row-column-solution .checkbox {
      display: flex;
      align-items: center;
    }
  
    .contact-form-label {
      margin-bottom: 0;
      margin-top: 36;
    }
  
    .image-text {
      position: absolute;
      top: 12.5rem;
      left: initial;
      color: white;
      font-size: 65;
      margin-left: 36;
    }
  
    .image-logo {
      width: 70%;
      position: absolute;
      top: 25rem;
      left: 15em;
    }
  
    .header-footer-solution-icon {
      font-size: 50px;
    }
  
    .header-footer-login-icon {
      font-size: 60px;
    }
  
    .mobile-margin-top {
      margin-top: 58px;
    }
  
    .solution-logo {
      width: 30%;
    }
  }
  .hero-section {
    height: 400px;
    width: 100%;
  }
  .hero_section_img {
    width: 100%;
    height: 460px;
    object-fit: cover;
  }
  
  /* Desktop Setting */
  
  @media screen and (max-width: 1171px) {
    :root {
      --row-element-min-width-1: 400;
      --row-element-min-width-2: 400;
      --contact-box-height: 500;
      --height: 50px;
    }
  
    .p-lp {
      color: var(--corporate-color-1);
      font-size: var(--font-size-large);
      text-align: justify;
      text-justify: auto;
      font-family: "Roboto", sans-serif;
    }
  
    .content-box-headline {
      font-size: var(--font-size-extra-big);
      color: var(--corporate-color-4);
      text-align: center;
      font-family: "Mulish", sans-serif;
    }
  
    
  
    .content-box-column-headline {
      font-size: var(--font-size-big);
      color: var(--corporate-color-4);
      text-align: center;
    }
  
    .top-bottom-margin {
      margin-top: 12;
      margin-bottom: 12;
    }
  
    .content-box-subheadline {
      font-size: var(--font-size-big);
      color: var(--corporate-color-1);
      text-align: center;
      margin-top: 72px;
      margin-bottom: 12px;
    }
  
    .emphasized-text {
      background-color: var(--corporate-color-6);
      font-size: var(--font-size-big);
      color: white;
    }
  
    .page-button {
      width: 200px;
      background-color: var(--corporate-color-2);
      color: white;
      margin-top: 72px;
      height: 45px;
      width: 90px;
      transition: 0.4s ease all;
    }
  
    .contact-textfield {
      width: 375;
      height: 40;
    }
  
    .contact-large-textfield {
      width: 375;
      height: 200;
    }
  
    .image-text {
      position: absolute;
      top: 100px;
      left: 10px;
      color: #bfa3a3;
      font-size: 3rem;
    }
  
    .image-logo {
      width: 600px;
      position: absolute;
      top: 145px;
      right: 70px;
      transform: translate(68px);
    }
  
    .solution-logo {
      width: 40%;
    }
  }