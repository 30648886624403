.attendee-row,
.top-row {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
}
/* .top-row.selected {
    background-color: gray;
} */

.top-row .top {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.top-row .top .previous {
    display: flex;
    flex-direction: column;
}

.top-row .top .previous .row {
    display: flex;
    flex-direction: row;
}

.top-row .top .latest {
    display: flex;
    flex-direction: row;
}

.attendee-row .top-type-badge,
.top-row .top-type-badge {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
}

.attendee-row .field,
.top-row .field {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
}

.attendee-row .text-field,
.top-row .text-field {
    width: 150px;
}

.attendee-row .large-text-field,
.top-row .large-text-field {
    flex: 3;
}

.attendee-row .date-field,
.top-row .date-field {
    width: 150px;
}

.attendee-row .dropdown-field,
.top-row .dropdown-field {
    width: 150px;
}
