.top-search-row {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
}

.top-search-row .top {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.top-search-row .top .previous {
    display: flex;
    flex-direction: column;
}

.top-search-row .top .previous .row {
    display: flex;
    flex-direction: row;
}

.top-search-row .top .latest {
    display: flex;
    flex-direction: row;
}

.top-search-row .top-type-badge {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
}

.top-search-row .field {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
}

.top-search-row .text-field {
    width: 150px;
}

.top-search-row .large-text-field {
    flex: 3;
}

.top-search-row .date-field {
    width: 150px;
}

.top-search-row .dropdown-field {
    width: 150px;
}

.global-dashboard-area {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 24px;
    border-radius: 30px;
    padding: 24px;
    box-shadow: 0px 0px 20px 0px var(--shadow-color-light);
}
.global-dashboard-area .content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex: 1;
}
.global-dashboard-area .diagram-container {
    width: 50vw;
    margin: 30px;
}

.global-dashboard-area .headline {
    color: var(--corporate-color-1);
    font-size: 28px;
    cursor: pointer;
}
.global-dashboard-area .count {
    color: var(--corporate-color-3);
    font-size: 38px;
    font-weight: bold;
    margin-top: 6px;
    cursor: pointer;
}

.global-dashboard-area .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.global-dashboard-area .column2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 30px;
    cursor: pointer;
    padding-left: 30px;
    padding-right: 30px;
}

.global-dashboard-area .hoverwienrover {
    transition: 0.3s ease;
}

.global-dashboard-area .hoverwienrover:hover{
    background-color: var(--corporate-color-5l);
}

.project-overview {
    display: flex;
    flex-wrap: wrap;
    padding: 24px; 
    padding-top: 12;
    gap: 24px; 
    justify-content: center;
}

.project-overview .project {
    display: flex;
    flex-direction: column;
    flex: 1 0 25%;
    min-width: 300px;
    border-radius: 30px;
    padding-bottom: 6px;
    overflow: hidden;
    box-shadow: 0px 0px 20px 0px var(--shadow-color-light);
}

.project .header {
    height: 30px;
    background-color: var(--corporate-color-5l);
    display: flex;
    align-items: center;
    padding-left: 24;
    padding-right: 18;
    color: var(--corporate-color-1);
    font-weight: bold;
}

.project .information {
    height: 45px;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--corporate-color-1)
}

.project .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    border-radius: 30px;
}

.project .title {
    font-weight: bold;
    color: var(--corporate-color-2)
}

.project .count {
    font-weight: bold;
    font-size: 18px;
    color: var(--corporate-color-4);
    margin-top: 6px;
}

.project .critical-title {
    color: var(--corporate-color-12)
}

.project .critical-count {
    font-weight: bold;
    font-size: 18px;
    color: var(--corporate-color-12);
    margin-top: 6px;
}

.project .project-container {
    display: flex;
    flex: 1;
    justify-content: center;
    border-bottom: 1px solid var(--corporate-color-5l);
    margin-bottom: 18px;
}

.project .project-information-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

