/* VARS */
:root {
    --font-family: sans-serif;
    --font-size-small: 0.7em;
    --font-size-normal: 1em;
    --font-size-large: 1.3em;
    --font-size-big: 2em;
    --font-size-extra-big: 3em;
    --font-size-mobile-small: 1.5em;
    --font-size-mobile-normal: 3em;
    --font-size-mobile-large: 4em;
    --font-size-mobile-big: 5em;
    --space-small: 0.5em;
    --space-norsmall: 0.75em;
    --space-normal: 1em;
    --space-normal-row: 1.5em;
    --space-normal-row-2: 1.2em;
    --space-big: 2em;
    --control-height: 2.3em;
    --control-label-pos: 0.2em;
    --color-1: rgb(70,70,70); 
    --color-2: darkgray; 
    --color-3: #e0e0e0;
    --color-3-light: #e0e0e075;
    --color-4: #fff;
    --color-success: green;
    --color-error: #FF0000;
    --color-error-light: #FF000073;
    --color-info: blue;
    --shadow-color-dark:rgba(0, 0, 0, 0.75);
    --shadow-color-normal:rgba(0, 0, 0, 0.5);
    --shadow-color-light:rgba(0, 0, 0, 0.3);
    --shadow-color-ultralight:rgba(0, 0, 0, 0.17);
    --corporate-color-1: #003459; 
    --corporate-color-1-light: #00345966; 
    --corporate-color-1-light2: #00345980; 
    --corporate-color-2: #005980; 
    --corporate-color-3: #007EA7; 
    --corporate-color-4: #64A6C1; 
    --corporate-color-5: #96BACE; 
    --corporate-color-5l: #96BACE5E; 
    --corporate-color-6: #C7CEDB;
    --corporate-color-6-light: #C7CEDBBF; 
    --corporate-color-6-ultralight: #C7CEDB40; 
    --corporate-color-7: #FFD166; 
    --corporate-color-7-light: #FFD16626; 
    --corporate-color-8: #E3D0A1; 
    --corporate-color-9: #A5668B; 
    --corporate-color-10: #70163C; 
    --corporate-color-10-light: #70163C80; 
    --corporate-color-11: #0ea700e1;     
    /* Top badge colors a, s, d */
    --corporate-color-12: #FF3A20;
    --corporate-color-14: #0CAADC;
    --corporate-color-13: #16DB65;
}

/* COMMON */

body, body * {
    font-family: var(--font-family);
    font-size: 14px;
    margin:0;
}

p {
    cursor: default;
}

button p {
    cursor: pointer;
}

button {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: none;
    border-radius: 20px;
    background-color: transparent;
    cursor: pointer;
    color: var(--corporate-color-1);
    height: var(--control-height);
    padding: var(--space-norsmall);
    transition: 0.2s ease;
}
button:hover {
    border: none;
    background-color: var(--shadow-color-ultralight);
    cursor: pointer;
}

input, textarea {
    border: none;
    border-radius: var(--space-small);
    padding-left: var(--space-small);
}

textarea {
    padding-top: var(--space-small);
}

:focus, :focus-visible, :active {
    outline: none;
}

.pdf-viewer {
    border-radius: var(--space-small);
    overflow: hidden;
    flex: 1;
}

.offline-syncer {
    background-color: var(--color-3);
    border: 2px solid var(--color-error);
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px var(--shadow-color-light);
}

/* TOASTS */

.toast.success {
    background-color: var(--color-3);
    border: 1px solid var(--corporate-color-11);
    border-radius: 10px;
    box-shadow: 0px 0px 30px 0px var(--corporate-color-11);
}
.toast.spinner {
    background-color: var(--color-3);
    border: 1px solid var(--corporate-color-3);
    border-radius: 10px;
    box-shadow: 0px 0px 30px 0px var(--corporate-color-3);
}
.toast.error {
    background-color: var(--color-3);
    border: 1px solid var(--corporate-color-10);
    border-radius: 10px;
    box-shadow: 0px 0px 30px 0px var(--corporate-color-10);
}

/* MODALS */

.modal-container {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 40px 0px var(--shadow-color-normal);
}

.modal-backdrop.open {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
}

.modal-container .content-container {
    background-color: var(--corporate-color-6); 
    border-style: none;
    padding: var(--space-normal);
    /* padding-top: var(--space-normal-row); */
    overflow-y: scroll;
}

.modal-container .content-container .columns {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    flex-wrap: wrap;
    gap: var(--space-normal);
    flex: 1;
}

.modal-container .content-container .columns > * {
    display: flex;
    flex-direction: column;
    flex: 1;
    row-gap: var(--space-small);
}


.modal-container .content-container .opp-headline { 
    display: flex;
    align-items: center;
    color: var(--corporate-color-2);
    font-size: 20px;
    border-bottom-style: solid;
    border-bottom-color: var(--corporate-color-2);
    border-bottom-width: 1px;
    cursor: default;
}

.modal-container .content-container .actions-headline { 
    color: var(--corporate-color-2);
    font-size: 18px;
    border-bottom-style: solid;
    border-bottom-color: var(--corporate-color-2);
    border-bottom-width: 1px;
    margin-top: 18;
    cursor: default;
}

.modal-container .content-container .opp-timeline { 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 12px;
    max-width: 82vw;
    flex-wrap: wrap;
    gap: 12px;
}

.modal-container .topbar-container button {
    border-radius: 0;
}

.modal-container .topbar-container {
    align-items: center;
    height: 35px;
    padding: var(--space-small);
    padding-left: var(--space-normal);
    background-color: var(--corporate-color-2);
    color: var(--color-1);
    border-style: none;
}

.modal-container .topbar-container .topbar-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-big);
}
.modal-container .topbar-container > * {
    height: 35px;
}

.modal-container .types-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/* SCREENS */

.screen-container.topbar-container {
    width: 100%;
    height: 120px;
}

.screen-container .topbar-container > * {
    width: 100%;
    height: 120px;
}

/* LIST */

.list-row {
    background-color: var(--color-3);
    margin-left: var(--space-small);
    margin-right: var(--space-small);
    margin-top: var(--space-normal-row-2);
    border-radius: var(--space-normal);
    overflow: hidden;
}

.attendee-row {
    padding: var(--space-small);
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: space-around;
    align-items: center;
}

.attendee-row .field.text-field {
    width: 30%;
    margin-left: 24px;
 }

.attendee-row .field.dropdown-field {
    width: 17.5%;
    margin-left: 24px;
 }

.list-row-buttons {
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 0;
}

/* HEADER */

.content-header-container {
    background-color: var(--corporate-color-6); 
    height: auto;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 12px;
    border-radius: 30px;
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.attendees-section {
    display: flex;
    flex-direction: column;
    padding-top: 6px;
}

/* FIELDS */
.field-column {
    display: flex;
    flex-direction: row;
    gap: var(--space-small)
}
.field-column button {
    flex: 1;
}
.field-column .field {
    flex: 3;
}

.field {
    display: flex;
    flex-direction: column;
    min-height: var(--control-height);
}

.field .input {
    background-color: var(--color-4);
    height: 100%;
    min-height: var(--control-height);
    padding: var(--space-small);
    border-radius: var(--space-small);
}
.field.image-field .input {
    height: 100%;
    min-height: 70px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.field.file-field .input {
    height: 60px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.field textarea {
    resize: vertical;
}

.label {
    cursor: default;
}

.field .label {
    font-size: var(--font-size-small);
    display: flex;
    opacity: 0;
    position: relative; height: auto;
    margin-top: var(--control-label-pos);
    font-size: var(--font-size-small);
    transition: opacity 0.4s ease;
}


.field.selected .label {
    opacity: 1;
}

.field.list-field .input {
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.field.list-field .row {
    cursor: pointer;
    padding: var(--space-small);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-3);
}

.field.list-field .row .buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.field.list-field .row .buttons .label {
    font-size: var(--font-size-normal);
    margin-left: 6px;
    margin-right: 12px;
    margin-top: 0px;
}

.field.list-field .row .buttons .checkbox {
    display: flex;
    align-items: center;
}

.field.list-field .row:hover {
    background-color: var(--color-3-light);
}

.field.list-field .row.selected {
    background-color: var(--color-2);
    color: var(--color-4);
}

.dropdown-field {
    width:149px;
}

.datepicker {
    height: var(--control-height);
}

.searchbar {
    height: 35px; 
    width: 300px; 
    border-radius: 17px;
    padding-left: 20px;
}


/* BUTTONS */

.button-icon {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    color: var(--corporate-color-1)
}

/* ELSE */

.dashboard-screen-upper-topbar {
    display: flex;
    align-items: center;
    height: 60px;
    background-color: var(--corporate-color-1); 
    padding-left: 32px;
    padding-right: 32px;
}

.border-radius-shadow {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0px 0px 20px 0px var(--shadow-color-dark);
}

.dashboard-screen-upper-topbar .label {
    color: var(--corporate-color-6); 
}

.dashboard-screen-lower-topbar {
    display: flex;
    align-items: center;
    background-color: var(--corporate-color-6); 
    height: 60px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0px 0px 40px 0px var(--shadow-color-normal);
    padding-left: 32px;
    padding-right: 32px;
}

.protocol-screen-upper-topbar {
    display: flex;
    align-items: center;
    height: 60px;
    background-color: var(--corporate-color-1);
    padding-left: 32px;
    padding-right: 32px;
}

.meeting-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--corporate-color-6); 
    padding-top: 6px;
    padding-bottom: 12px;
    padding-left: 32px;
    padding-right: 32px;
    align-items: center;
    border-radius: 30px;
    margin-left: 6px;
    margin-right: 6px;
    margin-top: 12px;
}

/* TOPS */

.top-row {
    display: flex;
    flex-direction: column;
}

.top-row .top-hierarchy {
    padding: var(--space-small);
    min-width: 200px;
    background-color: var(--corporate-color-5l)
}

.top-row .top-hierarchy-headline {
    padding: var(--space-small);
    min-width: 200px;
    background-color: var(--corporate-color-3);
    color: white
}

.top {
    margin: var(--space-small);
    gap: var(--space-small);
}

.top .previous .row {
    gap: var(--space-small);
}

.top .latest {
    gap: var(--space-small);
    align-items: flex-start;
}

.top .previous {
    color: var(--color-2)
}

.top .top-type-badge {
    height: 30px;
    width: 30px;
    background-color: darkgray;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    cursor: default;
    font-size: 15px;
}

.top .top-type-badge-headline {
    display: flex;
    height: 30px;
    width: 30px;
    background-color: var(--corporate-color-3);
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    cursor: default;
    color: white
}

.top .previous .top-type-badge {
    cursor: default;
    opacity: 0;
}

.top .top-business-id {
    margin-bottom: var(--space-small);
    cursor: default;
}

.top-search-row .checkbox {
    cursor: pointer;
    /* margin: var(--space-normal); */
    height: var(--space-normal);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    right: var(--space-normal);
}

.dashboard-top-button-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    background-color: var(--corporate-color-5l);
    height: 30px;
    overflow: hidden;
}

.assoc-button-container {
    display: flex; 
    justify-content: flex-end;
    position: relative;
}

.assoc-button {
    width: 150px;
    justify-content: space-around;
    color: var(--color-success);
    margin-right: 24px
}
.dissoc-button {
    width: 200px;
    justify-content: space-around;
    color: var(--corporate-color-10)
}

.top-search-row .checkbox input {
    margin-left: var(--space-small);
    height: var(--space-normal-row);
    width: var(--space-normal-row);
}


/* OLD */

.centerContainer {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0vh;
    right: 30vw;
  }

.centerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40vw;
    height: 65vh;
    background-color: rgb(240, 240, 240);
    border-style: none;
    border-radius: 35px;
    box-shadow: 0px 0px 40px 0px var(--corporate-color-2);
}

.button-blue {
    height: 35px;
    width: 100%;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    color: var(--corporate-color-2);
    border-color: var(--corporate-color-2);
    margin-bottom: 1px;
}

.button-blue:hover {
    border-width: 2px;
}

.button-quiet {
    height: 35px;
    width: 100%;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    border-width: 0;
    font-weight: normal;
    margin-bottom: 1px;
}

.button-quiet:hover {
    border-width: 1px;
}

.dashboard-toggle {
    width: 175px;
    color: var(--corporate-color-7);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50px;
}

.dashboard-toggle:hover {
    border: none;
    color: white;
    background-color: var(--corporate-color-5l);
    cursor: pointer;
}




/* LOADING ANIMATION */

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid var(--corporate-color-1); /* Blue */
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .dashboard-message {
      font-size: 25px;
      color: var(--corporate-color-1);
      text-align: center;
      margin-top: 190px;
  }

.attachments-container .attachment {
    display: flex;
    align-items: center;
    height: 25px;
    background-color: white;
    padding: 5px;
    justify-content: space-between;
    overflow: hidden;
}

.protocol-notice {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20vh;
    font-size: 30;
    color: var(--corporate-color-2);
}


.attachments-container {
    border-radius: var(--space-small);
    overflow: hidden;
    margin-bottom: 14px;
}

.hoverwienrover {
    transition: 0.3s ease;
    cursor: pointer;
}

.hoverwienrover:hover{
    background-color: var(--corporate-color-5l);
}

.dashboard-area {
    border-radius: 30px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 24px;
    padding: 18px;
    padding-bottom: 24px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: none;
}
