.charts-area {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.charts-area .headline {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--corporate-color-4);
    font-size: 22;
    font-weight: bold;
    color: var(--corporate-color-1);
    margin-left: 24;
    margin-right: 24;
    margin-bottom: 12;
    padding-bottom: 3;
    cursor: default;
}

.charts-area .layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.charts-area .opp-charts {
    display: flex;
    align-items: flex-start;
}

.charts-area .doughnut-container {
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: 300px;
}

.charts-area .inner-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20%;
    right: 20%;
    bottom: 20%;
    left: 20%;
    padding: 1.25em 0;
    line-height: 120%;
    z-index: -1; /* Allow tooltips on top */
    overflow: hidden;
}

.charts-area .inner-text .title {
    font-size: 22px;
    color: var(--corporate-color-2);
    margin-top: 9;
}

.charts-area .inner-text .info {
    font-size: 22px;
    color: var(--corporate-color-1);
    font-weight: bold;
    margin-top: 15;
}

.charts-area .inner-text .title-critical {
    font-size: 18px;
    color: var(--corporate-color-12);
    margin-top: 20;
}

.charts-area .inner-text .info-critical {
    font-size: 18px;
    color: var(--corporate-color-12);
    font-weight: bold;
    margin-top: 5;
}

.charts-area .chart-legend {
    display: flex;
    align-items: center;
    margin-bottom: 24;
    justify-content: space-between;
    width: 100%;
}

.charts-area .legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.charts-area .legend-item {
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 5vw;
    margin-right: 5vw;
}

.charts-area .legend-color {
    width: 27;
    height: 27;
    border-radius: 15px;
}

.charts-area .legend-title {
    color: var(--corporate-color-1);
    border-bottom: 1px solid var(--corporate-color-2);
    cursor: default;
    font-size: 17;
}

.charts-area .legend-label {
    color: var(--corporate-color-1);
    margin-left: 10;
    cursor: default;
    font-size: 17;
}

.charts-area .tables {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.inner .actions-headline {
    color: var(--corporate-color-1);
    font-size: 17;
    font-weight: bold;
}

.charts-area .actions-chart {
    display: flex;
    justify-content: center;
    width: 95vw;
    margin-top: 36;
    margin-bottom: 48;
}

.charts-area .actions-chart .inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60vw;
}

.inner p {
    color: var(--corporate-color-1);
    font-size: 17;
    margin-bottom: 20;
}

.table-container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    background-color: var(--corporate-color-6-ultralight);
    border-radius: 15px;
    width: 96%;
    margin-bottom: 10px;
    margin-top: 20px;
    cursor: default;
}

.table-container .table-header {
    display: flex;
    justify-content: space-between;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--corporate-color-2);
    margin-bottom: 12;
    padding-bottom: 3;
}

.table-container .header-entry-left {
    display: flex;
    justify-content: flex-start;
    font-size: 17px;
    font-weight: bold;
    width: 33%;
    color: var(--corporate-color-2);
}

.table-container .header-entry-middle {
    display: flex;
    justify-content: center;
    font-size: 17px;
    width: 33%;
}

.table-container .footer-entry-middle {
    display: flex;
    border-radius: 12px;
    justify-content: center;
    font-size: 17px;
    width: 33%;
    transition: 0.1s ease;
    cursor: pointer;
}
.table-container .footer-entry-middle:hover {
    font-weight: bold;
    color: white;
    background-color: darkgray;
}

.table-container .header-entry-right {
    display: flex;
    justify-content: flex-end;
    font-size: 17px;
    width: 33%;
    font-weight: bold;
    color: var(--corporate-color-2);
}

.table-container .table-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 3px;
    margin-bottom: 3px;
}

.table-container .table-entry-left {
    display: flex;
    justify-content: flex-start;
    font-size: 17px;
    width: 33%;
    overflow: hidden;
}

.table-container .table-entry-middle {
    display: flex;
    justify-content: center;
    font-size: 17px;
    border-radius: 12px;
    width: 33%;
    transition: 0.1s ease;
    cursor: pointer;
}

.table-container .table-entry-middle:hover{
    font-weight: bold;
    color: white;
    background-color: darkgray;
}

.global-dashboard-area .table-container .table-entry-middle:hover{
    font-weight: bold;
    color: black;
    background-color: var(--corporate-color-5l);
}

.table-container .table-entry-right {
    display: flex;
    justify-content: flex-end;
    font-size: 17px;
    width: 33%;
}

.table-container .footer-entry-right {
    display: flex;
    justify-content: flex-end;
    font-size: 17px;
    font-weight: bold;
    width: 33%;
    color: var(--corporate-color-11);
}

.table-container .table-footer {
    display: flex;
    justify-content: space-between;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--corporate-color-11);
    padding-top: 3px;
    margin-top: 9px;
}

.table-container .table-footer-critical {
    display: flex;
    justify-content: space-between;
}