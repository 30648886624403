.modal-backdrop {
    display: none;
}
.modal-backdrop.open {
    display: flex;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
}
.modal-container {
    width: 65vw;
    height: 65vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 17.5vh;
    left: 17.5vw;
    z-index: 1000;
}