.screen-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 100%;
}
.topbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
}
.topbar-container > * {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}
.content-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
}